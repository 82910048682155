/*========== Import ==========*/
@import url("font-awesome.css");
@import url("ionicons.css");
@import url("owl.carousel.css");
@import url("shortcodes.css");
@import url("pe-icon-7-stroke.css");
@import url("waves.min.css");
@import url("animate.css");
@import url('https://fonts.googleapis.com/css?family=Dosis:200,300,400,500,600,700,800');


/*==========
  1.  Reset
  2.  Style Patterns
  3.  Header
  4.  Page Title
  5.  Menu Left
  6.  TP Banner
  7.  Footer
  8.  Mobile Menu
  9.  Main Post
  10. Main Single
  11. Navigation Single
  12. Comment Single
  13. Preload
==========*/

/*========== Reset ==========*/
html {
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, caption, canvas, center, cite, code,
dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, form, footer, header, hgroup, h1, h2, h3, h4, h5, h6, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, tt, table, tbody, textarea, tfoot, thead, time, tr, th, td, u, ul, var, video {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    font-style: inherit;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: transparent;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    quotes: none;
}

figure {
    margin: 0;
}

:focus {
    outline: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
}

legend {
    white-space: normal;
}

button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    max-width: 100%;
    vertical-align: baseline;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

button,
input {
    line-height: normal;
}

input,
textarea {
    background-image: -webkit-linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0));
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    line-height: 1;
    cursor: pointer;
    -webkit-appearance: button;
    border: none;
}

input[type="checkbox"],
input[type="radio"] {
    padding: 0;
}

input[type="search"] {
    -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #1e1e1e;
    padding: 0px 30px 0 30px;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    color: #ffffff;
    background: #1e1e1e;
    font-family: "Dosis", sans-serif;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
input[type="email"] {
    position: relative;
    display: block;
    font-family: 'Dosis';
    width: 100%;
    padding: 8px 15px 8px 16px;
    color: #969696;
    border: 1px solid #d7d2bd;
    height: 50px;
    border-radius: 4px;
    background-color: transparent;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

textarea {
    height: 140px;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
    border: 1px solid #1e1e1e;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

textarea:-moz-placeholder,
textarea::-moz-placeholder,
input:-moz-placeholder,
input::-moz-placeholder {
    color: #969696;
    opacity: 1;
}

input:-ms-input-placeholder {
    color: #969696;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
    color: #969696;
    opacity: 1;
}

select,
textarea,
input[type="text"],
input[type="submit"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    color: #1e1e1e;
}

blockquote {
    position: relative;
    padding: 20px 55px 13px 97px;
    border: 1px solid #ebe9dc;
    border-radius: 4px;
    margin-bottom: 22px;
}

blockquote > span {
    position: absolute;
    top: 42px;
    left: 12px;
    line-height: 50px;
    font-style: italic;
    font-size: 120px;
    color: #1e1e1e;
}

blockquote p {
    font-style: italic;
    color: #1e1e1e;
    line-height: 24px;
    margin-bottom: 7px;
}

blockquote .author span {
    font-style: italic;
}

select::-ms-expand {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Dosis', sans-serif;
    font-weight: 400;
    line-height: 30px;
    color: #1e1e1e;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 25px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

/*========== Style Patterns ==========*/
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-family: 'Dosis', sans-serif;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    background-color: #fff;
    color: #969696;
    overflow: hidden;
    position: relative;
}

a {
    text-decoration: none;
    color: #1e1e1e;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

a:hover,
a:focus {
    color: #f28b00;
    text-decoration: none;
    outline: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

ul, ol {
    padding: 0;
}

img {
    max-width: 100%;
    height: auto;
}

b, strong {
    font-weight: 900;
}

button,
button:hover {
    border: none;
}

button:focus {
    outline: none;
}

h1.heading {
    position: absolute;
    top: 45%;
    font-family: 'Dosis', serif;
    color: white;
}

.main-slider {
    display: flex;
    align-items: center;
    justify-content: center;
}

h2.heading_sub {
    position: absolute;
    top: 55%;
    font-family: 'Dosis', serif;
    color: #d7d2bd;
    font-size: 22px;
}

.animated {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}


.container {
    width: 1170px;
    max-width: 100%;
}

.clearfix {
    clear: both;
}

.left {
    text-align: left;
}

.center {
    text-align: center;
}

#logo {
    display: inline-block;
}

.box-right {
    float: right;
}

.box-right .login a {
    color: #ffffff;
}

.box-right .login a:hover {
    color: #bf9c60;
}

/*========== Header ==========*/
#header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    border-top: 6px solid #d7d2bd;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#header.header-01.ver-1 {
    position: relative;
    background: #1e1e1e;
    padding: 25px 48px 25px 70px;
}

#logo {
    display: inline-block;
}

.box-right {
    float: right;
}

.box-right .login a {
    color: #ffffff;
}

/*========== Page Title ==========*/

.excursion-title {

    height: 394px;
    position: relative;

}

.excursion-title .top-page {
    position: absolute;
    bottom: 45px;
    left: -2px;
    width: 100%;

}

.excursion-title .top-page .top-page-heading h1 {
    color: #fff;
    font-weight: 400;
    margin-bottom: 25px;

}

.excursion-title .top-page .top-page-heading p {
    color: #fff;
    font-size: 18px;

}

.excursion-title .breadcrumbs {
    position: absolute;
    right: 2px;
    bottom: 107px;
    width: 100%;
}

.excursion-title .breadcrumbs ul {
    text-align: right;
}

.excursion-title .breadcrumbs ul li {
    display: inline-block;
}

.excursion-title .breadcrumbs li {
    color: #c5c5c5;
    font-size: 18px;
}

.excursion-title .breadcrumbs li a {
    color: #969696;
    font-size: 18px;
}

.excursion-title .breadcrumbs li a:hover {
    color: #bf9c60;
}
.top-title {
    background: url('../../src/images/template/page-title.jpg') no-repeat top center;
    height: 394px;
    position: relative;
}
.top-title.ver-1 {
    background: url('../../src/images/template/services-single.jpg') no-repeat top center;
    height: 394px;
    position: relative;
}

.top-title .top-page {
    position: absolute;
    bottom: 45px;
    left: -2px;
    width: 100%;
}

.top-title .top-page .top-page-heading h1 {
    color: #fff;
    font-weight: 400;
    margin-bottom: 25px;
}

.top-title .top-page .top-page-heading p {
    color: #fff;
    font-size: 18px;
}

.top-title .breadcrumbs {
    position: absolute;
    right: 2px;
    bottom: 107px;
    width: 100%;
}

.top-title .breadcrumbs ul {
    text-align: right;
}

.top-title .breadcrumbs ul li {
    display: inline-block;
}

.top-title .breadcrumbs li {
    color: #c5c5c5;
    font-size: 18px;
}

.top-title .breadcrumbs li a {
    color: #969696;
    font-size: 18px;
}

.top-title .breadcrumbs li a:hover {
    color: #bf9c60;
}

.header-01 .navigation {
    display: inline-block;
    width: 40%;
    padding-top: 23px;
}

.header-01 .onclick {
    position: relative;
    height: 30px;
    width: 40px;
    float: left;
    top: 10px;
    cursor: pointer;
}

.header-01 .logo-pro {
    position: absolute;
    top: 15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.main-menu {
    position: relative;
}

.main-menu .logo-pro-menu {
    text-align: center;
}

.main-menu .menu > li {
    display: block;
    padding: 0;
    margin-bottom: 15px;
}

.main-menu .menu > li > span {
    color: #969696;
    font-size: 40px;
    display: block;
    position: relative;
    line-height: 1;
    padding-left: 21px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.main-menu .menu > li > span:before {
    content: '';
    position: absolute;
    height: 30px;
    width: 1px;
    top: 6px;
    left: -1px;
    opacity: 0;
    background-color: #fff;
}

img.ikona {
    margin-right: 10px;
}

.main-menu .menu > li > span:hover:before,
.main-menu .menu > li > span.active:before {
    left: 0;
    opacity: 1;
}

.main-menu .menu li > span:hover,
.main-menu .menu li > span.active {
    color: #fff;
}

.header-01 .box-right .search {
    border-right: 1px solid #fff;
    padding-right: 28px;
}

.form-search #submit i {
    font-size: 48px;
    position: absolute;
    color: #2d2d2d;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.header-01 .box-right .login {
    border: 0;
}

.fix_headerbk {
    background: #1e1e1e;
}

.mht {
    margin-top: 90px;
}

.header-02 {
    padding-top: 27px;
    text-align: right;
}

.header-02 .box-right .login {
    margin-right: 24px;
}

.header-02 #logo {
    float: left;
    display: inline-block;
    padding-top: 5px;
}

.header-02 .navigation {
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    padding-right: 14px;
}

.navigation .menu > li > a {
    font-size: 20px;
    line-height: 45px;
    color: #fff;
    -webkit-transition: all 0.3 ease-in-out;
    -moz-transition: all 0.3 ease-in-out;
    -ms-transition: all 0.3 ease-in-out;
    -o-transition: all 0.3 ease-in-out;
    transition: all 0.3 ease-in-out;
}

.box-right .login, .box-right .search {
    display: inline-block;
    vertical-align: middle;
}

.box-right .login, .box-right .search i {
    vertical-align: middle;
}

.box-right .login {
    border: 1px solid #fff;
    padding: 7px 26px 7px 26px;
    border-radius: 3px;
    -webkit-transition: all 0.3 ease-in-out;
    -moz-transition: all 0.3 ease-in-out;
    -ms-transition: all 0.3 ease-in-out;
    -o-transition: all 0.3 ease-in-out;
    transition: all 0.3 ease-in-out;
}

.box-right .view_search {
    display: block;
}

.box-right .view_search i {
    font-size: 33px;
    color: #fff;
}

.box-right .form-search {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    display: none;
    background-color: #1e1e1e;
    padding: 15px 150px;
}

.box-right .form-search.active {
    -webkit-animation: fadeInUp 0.4s both;
    -moz-animation: fadeInUp 0.4s both;
    -o-animation: fadeInUp 0.4s both;
    animation: fadeInUp 0.4s both;
    display: block;
    top: 0px;
}

.box-right .form-search input {
    width: 100%;
    color: #969696;
    padding-left: 0px;
}

.box-right .form-search .action-text {
    border: 0;
    display: inline-block;
    width: calc(100% - 100px);
}

.close-fixed {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.box-right .form-search #submit {
    background: transparent;
    width: 40px;
    float: left;
    height: 47px;
    margin-right: 20px;
}

.box-right .form-search .close_x {
    float: right;
    cursor: pointer;
}

.navigation .menu li a:hover {
    color: #d7d2bd;
}

.box-right .search {
    line-height: 45px;
}

.header-02 .box-right {
    float: right;
}

.box-right .login a {
    color: #ffffff;
}

#header.header-03 {
    padding-top: 27px;
    background-color: #f5f5f5;
    border-top: 0;
}

.header-03 .logo-pro {
    text-align: center;
}

.header-03 .navigation {
    float: left;
    position: relative;
    top: 5px;
}

.header-03 .onclick {
    position: relative;
    text-indent: 65px;
    cursor: pointer;
    width: 40px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    float: left;
    color: #1e1e1e;
    top: 3px;
}

.header-03 .info-contact {
    display: inline-block;
    vertical-align: middle;
    margin-left: 83px;
    float: left;
}

.header-03 .info-contact li {
    display: inline-block;
    vertical-align: middle;
    color: #969696;
    border-left: 1px solid #c5c5c5;
    padding: 0 23px;
    position: relative;
}

.header-03 .info-contact li.vehicle {
    cursor: pointer;
    width: 100px;
}

.header-03 .info-contact .vehicle.active {
    text-indent: -9999px;
}

.header-03 .info-contact .vehicle.active:before {
    content: "\f404";
    font-family: 'ionicons';
    position: absolute;
    font-size: 50px;
    color: #1e1e1e;
    text-indent: 0;
    left: 50%;
    transform: translateX(-50%);
}

.header-03 .info-contact li:last-child {
    margin-left: -4px;
}

.header-03 .onclick span, .header-01 .onclick span {
    width: 40px;
    height: 1px;
    background: #fff;
    position: absolute;
    top: 15px;
    left: 0;
}

.header-03 .onclick:before, .header-03 .onclick:after, .header-01 .onclick:before, .header-01 .onclick:after {
    content: "";
    height: 1px;
    background: #fff;
    position: absolute;
    left: 0;
}

.header-03 .onclick:before, .header-01 .onclick:before {
    width: 40px;
    top: 4px;
}

.header-03 .onclick:before {
    background: #1e1e1e;
}

.header-03 .onclick:after, .header-01 .onclick:after {
    width: 35px;
    bottom: 4px;
}

.header-03 .onclick:after {
    background: #1e1e1e;
}

.header-03 .onclick span {
    background: #1e1e1e;
}

.main-menu .close_x {
    cursor: pointer;
    color: #fff;
}

.header-03 .navigation, .header-01 .navigation {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}

.header-03 .navigation.active, .header-01 .navigation.active {
    z-index: 999;
    visibility: visible;
    opacity: 1
}

.header-03 .main-menu, .header-01 .main-menu {
    position: fixed;
    padding-top: 30px;
    height: 100%;
    background: #1e1e1e;
    left: 0;
    top: 0;
    padding-left: 74px;
    padding-bottom: 50px;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    -webkit-animation: fadeInLeft 0.4s both;
    -moz-animation: fadeInLeft 0.4s both;
    -o-animation: fadeInLeft 0.4s both;
    animation: fadeInLeft 0.4s both;
    overflow-y: scroll;
    width: 720px;
    max-width: 100%;
    -webkit-transition: all 0.3 ease-in-out;
    -moz-transition: all 0.3 ease-in-out;
    -ms-transition: all 0.3 ease-in-out;
    -o-transition: all 0.3 ease-in-out;
    transition: all 0.3 ease-in-out;
}

.header-03 .main-menu > ul > li, .header-01 .main-menu > ul > li {
    margin-bottom: 20px;
}

.header-03 .main-menu .menu, .header-01 .main-menu .menu {
    margin-top: 110px;
}

.main-menu.active {
    opacity: 1;
    visibility: visible;
    margin-left: 0px;
    -webkit-animation: fadeInLeft 0.4s both;
    -moz-animation: fadeInLeft 0.4s both;
    -o-animation: fadeInLeft 0.4s both;
    animation: fadeInLeft 0.4s both;
}

#main-menu::-webkit-scrollbar {
    width: 4px;
    background-color: #1e1e1e;
}

#main-menu::-webkit-scrollbar-thumb {
    background-color: rgba(215, 210, 189, 0.9);
    cursor: pointer;
    border-radius: 4px;
}

.main-menu .has-dropdown {
    position: relative;
}

.main-menu > ul > li {
    display: block;
    padding: 0;
    line-height: 40px;
}

.main-menu > ul > li > a {
    color: #1e1e1e;
}

.main-menu .button {
    margin-top: 100px;
    margin-bottom: 53px;
    box-shadow: none !important;
}

.main-menu .button a {
    display: inline-block;
    position: relative;
    line-height: 50px;
    height: 50px;
    color: #d7d2bd;
    font-size: 16px;
    border: 1px solid #d7d2bd;
    border-radius: 4px;
    padding: 0 60px 0 32px;
}

.main-menu .button a:hover {
    background-color: #d7d2bd;
    color: #1e1e1e;
}

.main-menu .button a:before {
    content: "\f3d6";
    position: absolute;
    font-family: 'ionicons';
    top: 0;
    right: 30px;
    font-size: 26px;
}

.main-menu .button a.quote {
    margin-right: 30px;
}

.header-03 .box-right {
    text-align: right;
    float: right;
    position: relative;
    top: -3px;
}

.header-03 .box-right .login, .header-03 .box-right .quocte-booking {
    display: inline-block;
    vertical-align: middle;
}

.header-03 .box-right .login {
    margin-right: 6px;
    border: 0;
}

.header-03 .box-right .login a {
    color: #969696;
}

.header-03 .box-right .login a:hover {
    color: #bf9c60;
}

.header-03 .box-right .quocte-booking li {
    display: inline-block;
    vertical-align: baseline;
}

.header-03 .box-right .quocte-booking li:first-child a {
    border: 1px solid #bf9c60;
    color: #bf9c60;
    border-radius: 3px;
    margin-right: 21px;
    padding: 13px 46px 13px 46px;
}

.header-03 .box-right .quocte-booking li:last-child a {
    border: 1px solid #1e1e1e;
    border-radius: 3px;
    background: #1e1e1e;
    padding: 13px 36px 13px 36px;
    color: #d7d2bd;
}

.header-03 .box-right .quocte-booking li a:hover {
    background-color: #bf9c60;
    color: #fff;
    border-color: #bf9c60;
}

.header-01 .menu .has-dropdown .menu-dropdown, .header-03 .menu .has-dropdown .menu-dropdown {
    opacity: 1;
    visibility: visible;
    display: none;
    position: relative;
    padding-left: 21px;
    padding-top: 18px;
}

.menu-dropdown li {
    line-height: 32px;
}

.menu-dropdown li a {
    color: #969696;
    font-size: 16px;
    display: block;
}

.menu-dropdown li a:hover {
    color: #fff
}

#header.header-04 {
    line-height: 35px;
    border-top: 0;
}

.header-04 .main-menu .menu > li {
    margin-bottom: 0;
}

.header-04 .top-header, .header-05 .top-header {
    background-color: #1e1e1e;
}

.header-04 .navigation .menu > li > a {
    font-weight: 500;
    font-size: 18px;
    line-height: 70px;
    text-transform: uppercase;
}

.header-04 .top-header .right-content {
    padding-right: 35px;
}

.header-04 .bottom-header {
    background: rgba(30, 30, 30, 0.9);
    position: absolute;
    z-index: 9;
    width: 100%;
}

.header-04 .login a {
    color: #969696;
}

.header-04 .login a:hover {
    color: #bf9c60;
}

.header-04 .logo-pro {
    float: left;
    padding-top: 14px;
}

.header-04 .navigation {
    display: inline-block;
    vertical-align: middle;
}

.header-04 .box-right {
    line-height: 68px;
}

.header-04 .box-right .search {
    line-height: inherit;
}

.header-04 .box-right .form-search {
    line-height: 1;
}

.header-04 .box-right .view_search i {
    font-size: 26px;
}

.header-04 .top-header .left-content ul li, .header-05 .top-header .left-content ul li {
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    line-height: 30px;
    color: #969696;
    padding-right: 46px;
}

.header-04 .top-header .left-content ul li:last-child, .header-05 .top-header .left-content ul li:last-child {
    padding-right: 0;
}

.header-04 .top-header .left-content ul li img, .header-05 .top-header .left-content ul li img {
    padding-right: 10px;
    vertical-align: middle;
    position: relative;
    top: -2px;
}

.header-04 .top-header .right-content, .header-05 .top-header .right-content {
    text-align: right;
}

.header-04 .top-header .right-content .login, .header-04 .top-header .right-content .tr-w {
    display: inline-block;
    vertical-align: middle;
}

.tr-drop {
    font-family: Dosis;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #969696;
    padding-left: 15px;
    margin-left: 12px;
    border-left: 1px solid #383838;
    position: relative;
}

.tr-drop:after {
    content: "";
    border: solid 4px transparent;
    border-top: solid 4px #969696;
    position: absolute;
    top: 65%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: -18px;
}

.tr-w img {
    position: relative;
    top: -1px;
    padding-right: 9px;
}

/*========== Menu Left ==========*/
.top-menuleft:not(.no_bo) {
    position: relative;
    min-height: 12px;
    padding-right: 73px;
}

.top-menuleft.no_bo {
    display: inline-block;
    vertical-align: middle;
}

.top-menuleft.no_bo .x_language {
    border: 0;
    margin-top: 0px;
}

.header-01 .top-menuleft .x_close, .header-03 .top-menuleft .x_close {
    float: left;
    position: relative;
    top: -2px;
}

.top-menuleft .x_language {
    float: right;
    min-width: 80px;
    border: 1px solid #d7d2bd;
    border-radius: 4px;
    line-height: 26px;
    height: 26px;
    margin-top: 7px;
    vertical-align: middle;
    padding: 5px 0px 5px 10px;
    position: relative;
}

.top-menuleft .x_language:after {
    content: "";
    border: solid 5px transparent;
    border-top: solid 5px #d7d2bd;
    position: absolute;
    right: 5px;
    top: 45%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.top-menuleft .x_language #languages {
    border: 0;
    color: #d7d2bd;
    padding: 0 10px;
    max-width: 50px;
    line-height: 1;
    height: 14px;
    font-size: 13px;
}

.top-menuleft .x_language #languages option {
    background: #1c1c1c;
}

.top-menuleft .x_language img {
    float: left;
    margin-top: 2px;
}

.top-menuleft.no_bo {
    margin-left: 18px;
}

.top-menuleft.no_bo .x_language #languages {
    font-size: 15px;
    color: #969696;
}

.top-menuleft.no_bo .x_language:after {
    border-top: solid 5px #969696;
}

.header-01 .top-menuleft .x_logo, .header-03 .top-menuleft .x_logo {
    position: absolute;
    top: 50%;
    left: 43%;
    margin-top: 8px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#header.header-05 {
    padding-left: 36px;
    padding-right: 33px;
    border-top: 0;
}

.header-05 .navigation .menu > li > a {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 18px;
}

.header-05 .top-header {
    line-height: 50px;
}

.header-05 .top-header .login, .header-05 .top-header .tr-w, .header-05 .top-header .box-right {
    display: inline-block;
    vertical-align: middle;
}

.header-05 .top-header .login a {
    color: #969696;
}

.header-05 .top-header .login a:hover {
    color: #bf9c60;
}

.header-05 .tr-drop {
    border-left: 0;
}

.header-05 .box-right {
    padding-left: 18px;
}

.header-05 .view_search i {
    font-size: 23px;
    vertical-align: text-bottom;
}

.header-05 .tr-w {
    margin-right: 20px;
}

.header-05 .bottom-header {
    position: absolute;
    width: 100%;
    z-index: 9;
    left: 0;
    text-align: center;
}

.header-05 .main-menu > ul > li:not(.logo-pro_text):hover > a {
    background: #eeead7;
    border-radius: 3px;
    color: #1e1e1e;
}

.header-05 .main-menu ul .logo-pro_text {
    background: #1e1e1e;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-top: 29px;
    padding-bottom: 22px;
    padding-left: 30px;
    padding-right: 30px;
}

.header-05 .main-menu ul .logo-pro_text a {
    font-family: LOBSTER TWO;
    font-size: 45px;
    line-height: 45px;
    text-transform: capitalize;
}

/*========== TP Banner ==========*/
.tp-banner {
    position: relative;
    height: 100vh;
    overflow: hidden;
    background-color: #1e1e1e;
}

.calendar, .time-open, .book-adress {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: calc(100% + 0px);
    z-index: 200;
    opacity: 0;
    border-top: 6px solid #d7d2bd;
    visibility: hidden;
    background-color: rgba(30, 30, 30, 0.95);
}

.calendar.open, .time-open.open, .book-adress.open {
    opacity: 1;
    visibility: visible;
}

.calendar.open .logo-calendar, .time-open.open .logo-calendar {
    margin-bottom: 215px;
    margin-top: 20px;
}

.book-adress.open .logo-calendar {
    margin-top: 20px;
    margin-bottom: 205px;
}

.calendar .close, .time-open .close, .book-adress .close {
    position: absolute;
    top: 70px;
    right: 70px;
    cursor: pointer;
}

.calendar .logo-pro-calendar, .time-open .logo-pro-calendar, .book-adress .logo-pro-calendar {
    padding-top: 20px;
    margin-bottom: 180px;
}

.calendar .title h2 {
    color: #d7d2bd;
    font-size: 30px;
    margin-bottom: 50px;
}

.time-open .title {
    max-width: 660px;
    margin: 0 auto;
    margin-bottom: 56px;
}

.time-open .title h2 {
    font-size: 30px;
    line-height: 48px;
    color: #d7d2bd;
    margin-bottom: 13px;
}

.time-open .title p {
    font-size: 18px;
    line-height: 30px;
    color: #787878;
}

.form-address .search-address {
    position: relative;
}

.form-address .waves-effect {
    position: absolute;
    top: 18px;
    right: 20px;
    background: #161616;
}

.form-address .search-address {
    position: relative;
}

.form-address .waves-effect {
    position: absolute;
    top: 18px;
    right: 20px;
    background: #161616;
}

.form-address .list-address {
    margin-top: 45px;
    display: none;
}

.form-address .list-address.active {
    display: block;
    -webkit-animation: fadeInUp 0.4s both;
    -moz-animation: fadeInUp 0.4s both;
    -o-animation: fadeInUp 0.4s both;
    animation: fadeInUp 0.4s both;
}

.form-address .list-address li a {
    font-size: 20px;
    line-height: 40px;
    color: #7d7d7d;
}

.form-address .list-address li:first-child {
    margin-bottom: 24px;
}

.form-address .list-address li:first-child a {
    font-size: 25px;
    color: #ebe9dc;
}

.form-address .list-address li a:hover {
    color: #fff;
    margin-left: 5px;
}

.search-address .ui-helper-hidden-accessible {
    display: none;
}

.ui-autocomplete {
    padding-top: 45px;
    z-index: 9999 !important;
}

.ui-autocomplete li .ui-corner-all {
    font-size: 20px;
    line-height: 40px;
    color: #7d7d7d;
}

.ui-autocomplete li:first-child {
    margin-bottom: 24px;
}

.ui-autocomplete li:first-child .ui-corner-all {
    font-size: 25px;
    color: #ebe9dc;
}

.ui-autocomplete li .ui-corner-all:hover {
    color: #fff;
}

.form-address input {
    background: #161616;
    border-color: #161616;
    border-radius: 0;
    height: 85px;
    line-height: 85px;
    font-size: 23px;
    color: #565656;
    padding-left: 35px;
}

.calendar .calendar-item {
    display: inline-block;
    padding: 0 95px;
}

.calendar #calendarTemplate.calendar-item {
    position: relative;
}

.calendar #calendarTemplate.calendar-item:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 200px;
    top: 70px;
    right: 0;
    background-color: #7d7d7d;
}

/*========== Footer ==========*/
.footer-01, .footer-04 {
    background: #1e1e1e;
    padding: 70px 0;
}

.footer-03 {
    padding-top: 77px;
}

.footer-03 .widget-services {
    margin-top: 5px;
}

.footer-03 .widget-apps {
    margin-top: 10px;
}

.footer-03 .footer-bottom {
    margin-top: 44px;
    background: transparent;
}

.widget-about ul.social-ft li {
    display: inline-block;
    padding-right: 30px;
}

.bottom-01, .bottom-04 {
    padding: 30px 0 25px;
    background: #1e1e1e;
    border-top: 1px solid #393939;
}

.footer-bottom.has-parallax {
    border-top: 1px solid #393939;
    padding-top: 31px;
    padding-bottom: 25px;
    margin-top: 49px;
}

.footer-bottom .title {
    float: left;
    font-size: 18px;
    width: 26%;
    color: #fffffe;
}

.has-boxed {
    background-color: #1e1e1e;
}

.footer-bottom ul li {
    display: inline-block;
    padding-right: 65px;
}

.footer-bottom ul li a {
    color: #969696;
}

.footer-bottom ul li a:hover {
    color: #fff;
}

.footer-04 {
    padding: 77px 0 48px 0
}

.footer-04 .title-ft {
    font-weight: 400;
    text-transform: uppercase;
}

.copyright {
    text-align: center;
    background: #0e0e0e;
}

.copyright p {
    /* line-height: 70px; */
    color: #969696;
}

.cpr-01 {
    border-bottom: 5px solid #d7d2bd;
}

.footer-02 {
    background-color: #191919;
    padding-top: 58px;
}

.footer-02 .widget-footer {
    text-align: center;
}

.footer-02 .infomation-ft {
    margin-top: 21px;
}

.content-newletter {
    color: #969696;
    line-height: 24px;
}

#subscribe-content {
    border: 1px solid #969696;
    border-radius: 3px;
    margin-top: 29px;
}

#subscribe-content .field-one-third {
    display: inline-block;
    width: calc(100% - 60px);
}

#subscribe-content .btn-submit {
    float: right;
}

#subscribe-content #subscribe-email {
    border: 0;
    color: #fff;
}

#subscribe-content #subscribe-button {
    background: transparent;
    border: 0;
    width: 50px;
    display: inline-block;
    height: 45px;
}

#subscribe-content #subscribe-button img {
    position: relative;
    right: 10px;
    display: block;
    max-width: initial;
}

.footer-bottom.center {
    padding-left: 31px;
    padding-right: 31px;
    border: 0;
    background-color: #191919;
    padding-top: 27px;
    padding-bottom: 35px;
}

/*========== Mobile Menu ==========*/
.mobile-menu {
    position: relative;
    width: 40px;
    height: 25px;
}

.mobile-menu:before, .mobile-menu:after {
    content: "";
    position: absolute;
    background: #fff;
    height: 2px;
    width: 100%;
    left: 0;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

.mobile-menu:before {
    top: 0;
}

.mobile-menu:after {
    bottom: 0;
}

.mobile-menu span {
    background: #fff;
    height: 2px;
    position: absolute;
    left: 0;
    width: 70%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.mobile-menu.active:before, .mobile-menu.active:after, .mobile-menu.active span {
    background: #bf9c60;
}

/*========== Main Post ==========*/
#main-post {
    padding: 80px 0 57px;
}

#main-post.ver-1 {
    padding: 58px 0 67px;
}

article.post {
    border: 1px solid #ebe9dc;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 30px;
    text-align: center;
    cursor: pointer;
}

article.post .featured-image {
    overflow: hidden;
}

article.post .featured-image img {
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

article.post:hover .featured-image img {
    -webkit-transform: scale(1.1) rotate(-2deg);
    -moz-transform: scale(1.1) rotate(-2deg);
    -ms-transform: scale(1.1) rotate(-2deg);
    -o-transform: scale(1.1) rotate(-2deg);
    transform: scale(1.1) rotate(-2deg);
}

article.post .entry-content {
    padding: 30px 35px 19px;
}

article.post .entry-content .entry-post-title h4 {
    margin-bottom: 20px;
    font-weight: 400;
}

article.post .entry-content ul li {
    display: inline-block;
}

article.post .entry-content ul li.author {
    margin-right: 30px;
}

article.post .entry-content ul li a {
    position: relative;
}

article.post .entry-content ul li a img {
    margin-right: 10px;
}

/*= Tab Post =*/
.block-post {
    width: 100%;
}

ul.tab-post {
    text-align: center;
    padding: 0 15px;
    margin-bottom: 68px;
}

ul.tab-post li {
    display: inline-block;
    padding: 0 14px;
    font-size: 18px;
    color: #adaba5;
    cursor: pointer;
    line-height: 35px;
    border-radius: 5px;
    margin: 0 12px;
}

ul.tab-post li.active,
ul.tab-post li:hover {
    color: #d7d2bd;
    background: #1e1e1e;
}

/* Post Grid */
.post-grid article.post {
    width: 33.33%;
    padding: 0 15px;
    border: none;
    float: left;
    position: relative;
}

.post-grid article.post .featured-image {
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.post-grid article.post:hover .featured-image {
    opacity: 0;
}

.post-grid article.post .entry-content {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    background: #fff;
    padding-top: 50px;
    height: 100%;
    border: 2px solid #ebe9dc;
    border-radius: 4px;
    visibility: hidden;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(0) rotate(-180deg);
    -moz-transform: scale(0) rotate(-180deg);
    -o-transform: scale(0) rotate(-180deg);
    -ms-transform: scale(0) rotate(-180deg);
    transform: scale(0) rotate(-180deg);
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.post-grid article.post:hover .entry-content {
    opacity: 1;
    visibility: visible;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transform: scale(1) rotate(0deg);
    -moz-transform: scale(1) rotate(0deg);
    -o-transform: scale(1) rotate(0deg);
    -ms-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
}

.post-grid article.post .entry-content p {
    margin-top: 15px;
    color: #969696;
}

.post-grid article.post .entry-content .btn-readmore {
    margin-top: 15px;
}

.post-grid article.post .entry-content .btn-readmore a img {
    padding-left: 10px;
}

article.post.ver-1 .entry-content {
    padding: 26px 35px 22px;
}

article.post.ver-1 .entry-content .entry-post-title h4 {
    margin-bottom: 5px;
}

article.post.ver-1 .entry-content ul {
    margin-bottom: 9px;
}

article.post.ver-1 .entry-content p {
    line-height: 24px;
}

article.post.ver-1 .entry-content .btn-readmore {
    margin-top: 10px;
}

article.post.ver-1 .entry-content .btn-readmore a img {
    padding-left: 8px;
}

.pagination-area:not(.fix-mro) {
    margin-top: 34px;
    text-align: center;
}

.pagination-area.fix-mro {
    text-align: center;
    margin-top: 47px;
}

.pagination-area.ver-1 {
    margin-top: 49px;
}

.pagination-area.fix-fl {
    margin-top: 50px;
}

.pagination-area.ver-2 {
    margin-top: 19px;
}

.pagination-area.ver-3 {
    margin-top: 25px;
}

.pagination-area.ver-4 {
    margin-top: 70px;
}

.pagination-area ul li {
    display: inline-block;
    padding: 0 11px;
}

.pagination-area ul li a {
    height: 40px;
    width: 40px;
    line-height: 39px;
    text-align: center;
    display: block;
    border: 1px solid transparent;
    border-radius: 50%;
}

.pagination-area ul li.prev a,
.pagination-area ul li.prevs a,
.pagination-area ul li.nexts a,
.pagination-area ul li.next a {
    border-color: #d7d2bd;
    line-height: 41px;
    height: 47px;
    width: 47px;
}

.pagination-area ul li.active a,
.pagination-area ul li a:hover {
    background: #d7d2bd;
    color: #fff;
}

.pagination-area ul li.prev a:hover,
.pagination-area ul li.prevs a:hover,
.pagination-area ul li.nexts a:hover,
.pagination-area ul li.next a:hover {
    background: transparent;
}

/* Sidebar */
.sidebar .widget {
    margin-bottom: 30px;
    background: #f3f3f3;
    padding: 24px 32px 16px;
    border-radius: 4px;
}

.sidebar .widget:last-child {
    margin-bottom: 0px;
}

.sidebar .widget h3 {
    margin-bottom: 15px;
}

.widget.widget-search {
    padding: 0px;
}

.widget-search form .input-search {
    position: relative;
}

.widget-search form .input-search input {
    border: 1px solid transparent;
    height: 60px;
    padding: 0 60px 0 30px;
    border-radius: 4px;
}

.widget-search form .input-search input:focus {
    border-color: #969696;
}

.widget-search form .input-search button {
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
    line-height: 60px;
}

.widget-category ul li {
    line-height: 48px;
}

.widget-category ul li a:not(:hover) {
    color: #969696;
}

.widget-category ul li a:hover {
    color: #1e1e1e;
}

.widget-category ul li a img {
    padding-right: 18px;
}

.sidebar .widget-news {
    padding: 24px 34px 40px;
}

.sidebar .widget-news h3 {
    margin-bottom: 29px;
}

.widget-news ul li {
    overflow: hidden;
    margin-bottom: 20px;
}

.widget-news ul li:last-child {
    margin-bottom: 0px;
}

.widget-news ul li .news-image {
    border-radius: 4px;
    overflow: hidden;
    float: left;
    margin-right: 19px;
}

.widget-news ul li .news-content {
    margin-top: -4px;
}

.widget-news ul li .news-content h6 {
    line-height: 24px;
}

.widget-news ul li .news-content h6 a {
    color: #1e1e1e;
}

.widget-news ul li .news-content p.date {
    font-size: 14px;
    line-height: 24px;
    color: #969696;
}

.sidebar .widget-tags {
    padding: 24px 32px 27px;
}

.sidebar .widget-tags h3 {
    margin-bottom: 29px;
}

.widget-tags ul li {
    display: inline-block;
}

.widget-tags ul li a {
    display: block;
    padding: 0 13px;
    line-height: 35px;
    background: #fff;
    border-radius: 4px;
    margin-right: 7px;
    margin-bottom: 8px;
}

.widget-tags ul li a:hover {
    color: #fff;
    background: #1e1e1e;
}

/*========== Main Single ==========*/
#main-single {
    padding: 80px 0 72px;
}

article.post-single .featured-post {
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 22px;
}

article.post-single .post-title {
    margin-bottom: 13px;
}

article.post-single .post-title h4 {
    margin-bottom: 5px;
    letter-spacing: -0.1px;
}

article.post-single .post-title ul li {
    display: inline-block;
}

article.post-single .post-title ul li img {
    padding-right: 10px;
}

article.post-single .post-title ul li.author {
    margin-right: 25px;
}

article.post-single .post-content > p {
    margin-bottom: 22px;
    line-height: 24px;
}

/*= Direction =*/
.direction {
    padding: 19px 0 22px;
    border-top: 1px solid #ebe9dc;
    border-bottom: 1px solid #ebe9dc;
}

.direction .tags {
    margin-bottom: 14px;
}

.direction .tags span,
.direction .share span {
    color: #1e1e1e;
    padding-right: 9px;
}

.direction .tags a,
.direction .share a {
    color: #969696;
}

.direction .tags a:hover,
.direction .share a:hover {
    color: #1e1e1e;
}

.direction .share a {
    padding: 0 10px 0 3px;
    font-size: 14px;
}

/*========== Navigation Single ==========*/
.nav-single {
    padding: 26px 0 22px;
    border-bottom: 1px solid #ebe9dc;
}

.nav-single ul {
    overflow: hidden;
}

.nav-single ul li.prev {
    float: left;
    text-align: left;
    width: 50%;
    padding-right: 110px;
    position: relative;
}

.nav-single ul li.prev:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 70px;
    background-color: #ebe9dc;
    top: 0px;
    right: -1px;
}

.nav-single ul li.next {
    float: right;
    text-align: right;
    width: 50%;
    padding-left: 110px;
}

.nav-single ul li a {
    overflow: hidden;
    display: block;
    position: relative;
}

.nav-single ul li a .icon {
    position: absolute;
    top: 14px;
    left: 0;
    height: 47px;
    width: 47px;
    line-height: 43px;
    text-align: center;
    border: 1px solid #d7d2bd;
    border-radius: 50%;
}

.nav-single ul li a .text {
    overflow: hidden;
    padding-left: 66px;
}

.nav-single ul li.next a .icon {
    left: auto;
    right: 3px;
}

.nav-single ul li.next a .text {
    padding-right: 69px;
    padding-left: 0px;
}

.nav-single ul li a .text p {
    color: #969696;
}

.nav-single ul li a .text h4 {
    margin-bottom: 2px;
}

.nav-single ul li a .text p,
.nav-single ul li a .text h4 {
    line-height: 24px;
    font-weight: 400;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.nav-single ul li a:hover .text h4 {
    color: #bf9c60;
}

/*========== Comment Single ==========*/
#comment-area {
    margin-top: 31px;
}

#comment-area h4 {
    margin-bottom: 31px;
}

#comment-area ul.comment-list li.comment:not(:last-child) {
    margin-bottom: 34px;
}

#comment-area ul.comment-list li.comment .avatar {
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    margin-right: 13px;
    margin-top: 6px;
    float: left;
}

#comment-area ul.comment-list li.comment .comment-content {
    overflow: hidden;
}

#comment-area ul.comment-list li.comment .comment-content .name {
    color: #1e1e1e;
    line-height: 24px;
}

#comment-area ul.comment-list li.comment .comment-content span.date {
    color: #969696;
    font-size: 13px;
    padding-left: 7px;
}

#comment-area ul.comment-list li.comment .comment-content p {
    line-height: 24px;
}

#comment-area ul.comment-list li.comment .comment-content .reply {
    line-height: 24px;
    font-size: 14px;
}

#comment-area ul.comment-list li.comment .comment-content .reply a {
    color: #bf9c60;
}

#comment-area ul.comment-list li.comment .comment-content .reply a i {
    padding-right: 9px;
}

.form-comment-area {
    margin-top: 37px;
}

.form-comment-area h4 {
    margin-bottom: 27px;
}

.form-comment-area form .comment-form {
    margin-bottom: 18px;
    overflow: hidden;
}

.form-comment-area form .comment-form label {
    color: #1e1e1e;
    margin-bottom: 5px;
}

.form-comment-area form .comment-form .one-half {
    width: 50%;
    float: left;
}

.form-comment-area form .comment-form .comment-form-name {
    padding-right: 15px;
}

.form-comment-area form .comment-form .comment-form-email {
    padding-left: 15px;
}

.form-comment-area form .btn-submit {
    text-align: right;
    margin-top: 27px;
}

.form-comment-area form .btn-submit img {
    padding-left: 14px;
}

.form-comment-area form .btn-submit button:hover {
    color: #FFF;
    background: #bf9c60;
}

.scroll-top {
    background-image: url('../../src/images/icon/go_top.png');
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 41px;
    text-align: center;
    position: fixed;
    bottom: 19px;
    right: 10px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

footer {
    background: #1e1e1e;
    padding: 79px 0 48px;
}


.logo-ft {
    margin-bottom: 25px;
}

.widget-footer ul {
    margin-top: 15px;
}

.widget-footer ul li,
.widget-footer ul li a,
ul.social-ft li a {
    color: #969696;
}

ul.social-ft li a:hover {
    color: #fff;
}

.widget-footer ul li a:hover {
    color: #ffffff;
}

.widget-footer h3.title-ft {
    color: #fffffe;
    margin-bottom: 25px;
}

.widget-about .logo-pro-ft {
    margin-bottom: 21px;
}

.widget-footer ul.social-ft {
    margin-top: 15px;
    padding-left: 0px;
}

ul.social-ft {
    margin-top: 15px;
    padding-left: 21px;
}

ul.social-ft li {
    display: inline-block;
    padding-right: 28px;
}

ul.social-ft li:last-child {
    padding-right: 0px;
}

.widget-apps {
    margin-top: 8px;
}

.widget-apps li.app-store {
    margin-bottom: 36px;
}

.widget-apps li span {
    display: block;
    border: 1px solid #d7d2bd;
    border-radius: 4px;
    padding: 14px 23px 10px;
    position: relative;
}

.widget-apps li span:hover {
    background: #0e0e0e;
    border: 1px solid #0e0e0e;
}

.widget-apps li span .image-app {
    float: left;
    margin-right: 32px;
    position: absolute;
    top: 14px;
    left: 23px;
}

.widget-apps li span .text {
    padding-left: 56px;
}

.widget-apps li span .text h5 {
    line-height: 18px;
    color: #ffffff;
    margin-bottom: 7px;
}

.widget-apps li span .text p {
    line-height: 24px;
}

.footer-bottom {
    padding: 32px 0 25px;
    background: #1e1e1e;
    border-top: 1px solid #393939;
}

.footer-bottom ul li {
    display: inline-block;
    padding-right: 61px;
}

.footer-bottom ul li:last-child {
    padding-right: 0px;
}

.footer-bottom ul li a:not(:hover) {
    color: #969696;
}

.copyright {
    padding: 22px 0;
    text-align: center;
    background: #0e0e0e;
}

.copyright p {
    color: #969696;
}

/*========== Preload ==========*/
#loading-overlay {
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background: #fff;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
}

.loader:before,
.loader:after {
    position: absolute;
    display: inline-block;
    width: 60px;
    height: 60px;
    content: "";
    -webkit-animation: preloader-wave 1.6s linear infinite;
    animation: preloader-wave 1.6s linear infinite;
    border-radius: 50%;
    background: #bf9c60 !important;
}

.loader:after {
    -webkit-animation-delay: -.8s;
    animation-delay: -.8s;
}

.kalendar_pozadina {
    background: #3d3d3d !important;

}

.react-datepicker__day {
    color: white !important;
}

.react-datepicker__current-month {
    background: #3d3d3d !important;
}
.react-datepicker__day-names {
    background: #3d3d3d !important;
}
.react-datepicker__day-name {
    color: #bf9c60!important;
    font-weight: 900;
}

.react-datepicker__header {
    background: #bf9c60 !important;
    padding-top: 0!important;
}
.react-datepicker__navigation--previous {
    border-right-color:white!important;
    top: 18px!important;
}
.react-datepicker__navigation--next:hover
{
    background: white!important;
    cursor:pointer!important;
}
.react-datepicker__navigation--previous:hover
{
    background: white!important;
    cursor:pointer!important;
}
.react-datepicker__navigation--next {
    border-left-color:white!important;
    top: 18px!important;
}
.react-datepicker__current-month {
    background: #bf9c60!important;
    color: white!important;
    padding: 10px!important;
}

.react-datepicker__day--keyboard-selected {
    background-color: #bf9c60 !important;
}

.react-datepicker__day--selected {
    background-color: #bf9c60 !important;
}

.pointer {
    cursor: pointer;
}

.bila {
    color: white;
}

.pac-item-query {

    color: #999;
    font-size: 18px;
}

.pac-item:hover {
    background-color: #e4e4e4
}

.pac-item-selected,
.pac-item-selected:hover {
    background-color: #ebf2fe
}

.pac-item {
    border: none;
    cursor: default;
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;

    font-size: 11px;
    color: #999
}

.pac-container:after {
    /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

    background-image: none !important;
    height: 0px;
}

.pac-matched {

    color: #bf9c60;
}

.pad-left {
    padding-left: 5px;
    margin-right: 10px;
}

.hdpi .pac-icon {
    background-image: url(//maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png);
}

.pac-icon-search {
    background-position: -1px -1px
}

.pac-item-selected .pac-icon-search {
    background-position: -18px -1px;

}

.pac-icon-marker {
    background-position: -1px -161px
}

.pac-item-selected .pac-icon-marker {
    background-position: -18px -161px
}

.pac-placeholder {
    color: gray;

}

.pad-icon {
    width: 15px;
    height: 20px;
    margin-right: 7px;
    margin-top: 6px;

    display: inline-block;
    vertical-align: top;
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
    background-size: 34px
}

.pac-containers {
    background: rgba(30, 30, 30, 0.95);
    z-index: 999;

    width: 100%;
    box-shadow: none;
    display: contents;


}

.pac-container {
    background: rgba(30, 30, 30, 0.95);
    z-index: 999;
    border: none;
    width: 100%;
    box-shadow: none;


}

.react-datepicker-time__header {
    color: white!important;
    font-weight: 900;
}

.react-datepicker__time-container {
    width: 150px!important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width:150px!important;
}
.top100 {
    padding-top: 100px;
}

.react-datepicker-popper {
    z-index: 999 !important;
}

.top50 {
    padding-top: 50px;
}

.bot50 {
    padding-bottom: 50px;
}

i.fa.fa-calendar.test {
    position: absolute;
    margin-top: 35px;
    margin-left: -20px;
}

i.fa.fa-map-marker.test {
    position: absolute;
    margin-top: 32px;
    margin-left: -20px;
}

i.fa.fa-clock-o.test {
    position: absolute;
    margin-top: 37px;
    margin-left: -25px;
}

.react-datepicker-wrapper {
    display: block;
}

.dodatno4 {
    padding-top: 10px;
}

.dodatno3 {
    padding-top: 57px;
}

.dodatno2 {
    margin-top: 57px;
    color: black;
}

.react-datepicker__close-icon::after {
    background-color: #bf9c60 !important;
}

button.react-datepicker__close-icon.botuncic {
    position: absolute;
    left: 200px;
    top: 17px;
}

button.react-datepicker__close-icon.botuncic2 {
    position: absolute;
    left: 450px;
    top: 17px;
}

.dodatno1 {
    margin-top: 5px;
    color: black;
}

.black {
    color: black;
}

.pad-bot {
    padding-bottom: 20px !important;
}

.botun {
    text-align: center;
    padding-top: 5px;
}

.neka-klasa {
    border: 1px solid red !important;
}

.neka-klasa2 {
    color: red !important;
}
.search_submit {
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    background: #bf9c60;
    border-radius: 3px;
    font-weight: 600;
}

input.datum.pointer.test {
    border: none;
    color: white;
    padding: 0;
    height: 20px;
    margin: 0;
    line-height: 0;
}

input.input-stil {
    color: white;
    border: none;
    height: 30px;
    padding: 0;
}

.react-datepicker__navigation {
    margin-top: -5px !important;
}

.react-datepicker__navigation-icon--next::before, .react-datepicker__navigation-icon--previous::before {
    width: 18px !important;
    height: 18px !important;
    top: 14px !important;
    margin-top: 3px !important;
}

input[type="text"]:focus {

    padding-left: 5px;
}

.input.datum.pointer.react-datepicker-ignore-onclickoutside {
    color: transparent !important;
    text-shadow: 0 0 0 #2196f3 !important;
    outline: none !important;
}

ul.booking-steps {
    display: flex;
    justify-content: center;
}

@-webkit-keyframes preloader-wave {
    0% {
        -webkit-transform: scale(0, 0);
        transform: scale(0, 0);
        opacity: .5;
    }
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 0;
    }
}

@keyframes preloader-wave {
    0% {
        -webkit-transform: scale(0, 0);
        transform: scale(0, 0);
        opacity: .5;
    }
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 0;
    }
}
