.rcw-conversation-container .rcw-header {
    background-color: #bf9c60;
    border-radius: 10px 10px 0 0;
    color: #fff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    padding: 15px 0 25px
}

.rcw-conversation-container .rcw-title {
    font-size: 24px;
    margin: 0;
    padding: 15px 0;
    color:white;
}

.rcw-conversation-container .rcw-close-button {
    display: none
}

.rcw-conversation-container .avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 10px;
    vertical-align: middle
}

.rcw-full-screen .rcw-header {
    border-radius: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative
}

.rcw-full-screen .rcw-title {
    padding: 0 0 15px
}

.rcw-full-screen .rcw-close-button {
    background-color: #bf9c60;
    border: 0;
    display: block;
    position: absolute;
    right: 10px;
    top: 20px;
    width: 40px
}

.rcw-full-screen .rcw-close {
    width: 20px;
    height: 20px
}

@media screen and (max-width:800px) {
    .rcw-conversation-container .rcw-header {
        border-radius: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        position: relative
    }
    .rcw-conversation-container .rcw-title {
        padding: 0 0 15px
    }
    .rcw-conversation-container .rcw-close-button {
        background-color: #bf9c60;
        border: 0;
        display: block;
        position: absolute;
        right: 10px;
        top: 20px;
        width: 40px
    }
    .rcw-conversation-container .rcw-close {
        width: 20px;
        height: 20px
    }
}

.rcw-message {
    margin: 10px;
    display: -ms-flexbox;
    display: flex;
    word-wrap: break-word
}

.rcw-client {
    background-color: #969696;
    margin-left: auto;
    color:white;
}

.rcw-client,
.rcw-response {
    border-radius: 10px;
    padding: 15px;
    max-width: 215px;
    text-align: left
}

.rcw-response {
    background-color: #f4f7f9
}

.rcw-message-text p {
    margin: 0
}

.rcw-avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 10px
}

.rcw-snippet {
    background-color: #f4f7f9;
    border-radius: 10px;
    padding: 15px;
    max-width: 215px;
    text-align: left
}

.rcw-snippet-title {
    margin: 0
}

.rcw-snippet-details {
    border-left: 2px solid #35e65d;
    margin-top: 5px;
    padding-left: 10px
}

.rcw-link {
    font-size: 12px
}

.quick-button {
    background: none;
    border-radius: 15px;
    border: 2px solid #bf9c60;
    font-weight: 700;
    padding: 5px 10px;
    cursor: pointer;
    outline: 0
}

.quick-button:active {
    background: #bf9c60;
    color: #fff
}

.loader {
    margin: 10px;
    display: none
}

.loader.active {
    display: -ms-flexbox;
    display: flex
}

.loader-container {
    background-color: #f4f7f9;
    border-radius: 10px;
    padding: 15px;
    max-width: 215px;
    text-align: left
}

.loader-dots {
    display: inline-block;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background: gray;
    margin-right: 2px;
    animation: a .5s ease infinite alternate
}

.loader-dots:first-child {
    animation-delay: .2s
}

.loader-dots:nth-child(2) {
    animation-delay: .3s
}

.loader-dots:nth-child(3) {
    animation-delay: .4s
}

@keyframes a {
    0% {
        transform: translateY(0)
    }
    to {
        transform: translateY(5px)
    }
}

.rcw-messages-container {
    background-color: #fff!important;
    height: 50vh!important;
    max-height: 410px!important;
    overflow-y: scroll!important;
    padding-top: 10px!important;
    -webkit-overflow-scrolling: touch!important
}

.rcw-full-screen .rcw-messages-container {
    height: 100%!important;
    max-height: none!important
}

@media screen and (max-width:800px) {
    .rcw-messages-container {
        height: 100%!important;
        max-height: none!important
    }
}

.rcw-sender {
    -ms-flex-align: center!important;
    align-items: center!important;
    display: -ms-flexbox!important;
    display: flex!important;
    background-color: #f4f7f9!important;
    height: 45px!important;
    padding: 5px!important;
    border-radius: 0 0 10px 10px!important
}

.rcw-new-message {
    width: 100%!important;
    border: 0!important;
    background-color: #f4f7f9!important;
    height: 30px!important;
    padding-left: 15px!important
}

.rcw-new-message:focus {
    outline: none!important;
}

.rcw-send {
    background: #f4f7f9!important;
    border: 0!important;
    line-height: 0!important;
    height:0px!important;
}

.rcw-send .rcw-send-icon {
    height: 25px!important;
    position: absolute;
    left: 10px;
    top: -10px;
}

@media screen and (max-width:800px) {
    .rcw-sender {
        border-radius: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0
    }
}

.quick-buttons-container {
    background: #fff;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px
}

.quick-buttons-container .quick-buttons {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center
}

.quick-buttons-container .quick-buttons .quick-list-button {
    display: inline-block;
    margin-right: 10px
}

@media screen and (max-width:800px) {
    .quick-buttons-container {
        padding-bottom: 15px
    }
}

.rcw-conversation-container {
    -webkit-animation-delay: 0s;
    -webkit-animation-duration: .5s;
    -webkit-animation-name: d;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0s;
    -moz-animation-duration: .5s;
    -moz-animation-name: d;
    -moz-animation-fill-mode: forwards;
    animation-delay: 0s;
    animation-duration: .5s;
    animation-name: d;
    animation-fill-mode: forwards;
    border-radius: 10px;
    box-shadow: 0 2px 10px 1px #b5b5b5
}

.rcw-slide-out {
    -webkit-animation-delay: 0s;
    -webkit-animation-duration: .5s;
    -webkit-animation-name: e;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0s;
    -moz-animation-duration: .5s;
    -moz-animation-name: e;
    -moz-animation-fill-mode: forwards;
    animation-delay: 0s;
    animation-duration: .5s;
    animation-name: e;
    animation-fill-mode: forwards
}

.rcw-full-screen .rcw-conversation-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%
}

@media screen and (max-width:800px) {
    .rcw-conversation-container {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 100%
    }
}

.rcw-launcher .rcw-badge {
    display: none;
    position: fixed;
    top: -10px;
    right: -5px;
    background-color: red;
    color: #fff;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%
}

.rcw-launcher {
    display: none;
    -webkit-animation-delay: 0s;
    -webkit-animation-duration: .5s;
    -webkit-animation-name: d;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0s;
    -moz-animation-duration: .5s;
    -moz-animation-name: d;
    -moz-animation-fill-mode: forwards;
    animation-delay: 0s;
    animation-duration: .5s;
    animation-name: d;
    animation-fill-mode: forwards;
    -ms-flex-item-align: end;
    align-self: flex-end;
    background-color: #bf9c60;
    border: 0;
    border-radius: 50%;
    box-shadow: none;
    height: 60px;
    margin-top: 10px;
    width: 60px
}

.rcw-launcher:focus {
    display: none;
    outline: none
}

.rcw-open-launcher {
    -webkit-animation-delay: 0s;
    -webkit-animation-duration: .5s;
    -webkit-animation-name: c;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0s;
    -moz-animation-duration: .5s;
    -moz-animation-name: c;
    -moz-animation-fill-mode: forwards;
    animation-delay: 0s;
    animation-duration: .5s;
    animation-name: c;
    animation-fill-mode: forwards;
    position: absolute;
    top: 13px;
    left: 13px;
}

.rcw-close-launcher {
    width: 20px;
    -webkit-animation-delay: 0s;
    -webkit-animation-duration: .5s;
    -webkit-animation-name: b;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0s;
    -moz-animation-duration: .5s;
    -moz-animation-name: b;
    -moz-animation-fill-mode: forwards;
    animation-delay: 0s;
    animation-duration: .5s;
    animation-name: b;
    animation-fill-mode: forwards;
    position: absolute;
    top: 20px;
    left: 20px;
}

@media screen and (max-width:800px) {
    .rcw-launcher {
        display: none;
        bottom: 0;
        margin: 20px;
        position: fixed;
        right: 0
    }
    .rcw-hide-sm {
        display: none
    }
}

@keyframes b {
    0% {
        transform: rotate(-90deg)
    }
    to {
        transform: rotate(0)
    }
}

@keyframes c {
    0% {
        transform: rotate(90deg)
    }
    to {
        transform: rotate(0)
    }
}

@keyframes d {
    0% {
        opacity: 0;
        transform: translateY(10px)
    }
    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes e {
    0% {
        opacity: 1;
        transform: translateY(0)
    }
    to {
        opacity: 0;
        transform: translateY(10px)
    }
}

.rcw-widget-container {
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 20px 20px 0;
    max-width: 370px;
    position: fixed;
    right: 0;
    z-index: 134
}

.rcw-opened {
    width: 90vw
}

.rcw-full-screen {
    margin: 0;
    max-width: none;
    width: 100%
}

.rcw-full-screen.rcw-opened {
    width: 100%;
    height: 100%
}

@media screen and (max-width:800px) {
    .rcw-widget-container {
        margin: 0;
        max-width: none;
        width: 100%
    }
    .rcw-widget-container.rcw-opened {
        height: 100%
    }
}
