body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.menu-item-link {
  color: #969696;
  font-size: 40px;
  display: block;
  position: relative;
  line-height: 1;
  padding-left: 21px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.list-address {
  z-index: 999;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.chat-trigger {
  position: fixed;
  bottom: 5px;
  right: 5px;
  width: 61px;
  height: 61px;
  display: block;
  background: #c40f22 url(https://admin.v12dev.com/youssef/ui_chat/assets/img/v12_chat_icon_red.svg) no-repeat center;
  background-size: 35px;
  border-radius: 40px;
  cursor: pointer;
}

.container-chat {
  width: 400px;
  margin: 0 auto;
  border: solid 1px #ccc;
  border-radius: 5px;
  overflow: hidden;
  position: fixed;
  bottom:0;
  right: 0;
  z-index: 999;

}
.chat-container {
  height: 400px;
  overflow:auto;
  transform:rotate(180deg);
  direction:rtl;
  background: #F6F6F7;
}
.message {
  border-bottom: solid 1px #ccc;
  padding: 10px;
  transform: rotate(180deg);
  direction: ltr;
  background: white;
  margin-bottom: 5px;
  font-family: 'Dosis', serif;
  color: black;
}
.avatar {
  float:left;
  margin-right:5px;
}
.datetime {
  float:right;
  color:#bf9c60;
  font-size:12px;
}
.send-message-div {
  width:100%;
  border:none;
  font-size:16px;
  padding:10px;
  background: white;
}
/* Page Styles */
.alert-boxes{
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 300px;
  height: 200px;
  background: white;
  z-index: 999;
  border-radius: 10px;
  border: 2px solid #BF9C61;

}
.alert-right {
  width: 50%;
  float: right;
  padding-top: 40px;
  text-align: center;
}
.alert-left {
  width: 50%;
  float: right;
  padding-top: 40px;
  text-align: center;
}
.alert-button-right{
  width:80%;
  background: #BF9C61;
}

.alert-button-left {
  width:80%;
  background: #BF9C61;
}
.alert-container {
  padding:10px;
}
.alert-text {
  color: black;;
}
.alert-text h2 {
  color:black;
  text-align: center;
}
.alert-text h4 {
  color:#BF9C61;
  text-align: center;
  padding-top: 20px;
}
.container-notification {
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin: 0 20px 90px 0;
  max-width: 370px;
  position: fixed;
  right: 0;
  z-index: 134;
}

/* Notifications */

.notification {
  display: inline-block;
  position: relative;
  padding: 0.6em;
  background: #bf9c61;
  border-radius: 50%;
  font-size: 1.7em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.notification::before,
.notification::after {
  color: #fff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.notification::before {
  display: block;
  content: "\f0f3";
  font-family: "FontAwesome";
  transform-origin: top center;
}

.notification::after {
  font-family: Arial;
  font-size: 0.7em;
  font-weight: 700;
  position: absolute;
  top: -15px;
  right: -15px;
  padding: 5px 8px;
  line-height: 100%;
  border: 2px #fff solid;
  border-radius: 60px;
  background: #3498db;
  opacity: 0;
  content: attr(data-count);
  opacity: 0;
  transform: scale(0.5);
  transition: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.notification.notify::before {
  animation: ring 1.5s ease;
}

.notification.show-count::after {
  transform: scale(1);
  opacity: 1;
}

.success-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 100px 20px;
  & h3 {
    max-width: 550px;
    margin-bottom: 20px;
  }
}

@keyframes ring {
  0% {
    transform: rotate(35deg);
  }
  12.5% {
    transform: rotate(-30deg);
  }
  25% {
    transform: rotate(25deg);
  }
  37.5% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(15deg);
  }
  62.5% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
